import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">In Memory</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            Custom digital tribute of your loved ones &mdash; for sharing memories that will last forever.
          </h2>
          <p><a className="primary" href="mailto:v@marketgrowth.io">Contact us</a>.</p>
        </div>
      </div>
    </header>
  </Layout>
);

export default IndexPage;
